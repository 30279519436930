html, body, #app, #app>div, #root {
  margin: 0;
  padding: 0;
  font-family: "HelveticaNeue-CondensedBold";
}

.clickable {
  cursor:pointer;
}

$green: #3ce3b4;

.nav {
  position: fixed;
  top: 0;
  width: 100%;

  &__list {
    margin-block-end: 0;
    margin: 0;
    margin-block-start: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-between;
    padding-inline-start: 0;
    padding: 8px;
    color: white;
    background-color: black;

    &-items {
      align-items: center;
      display: flex;
      flex-direction: row;
      list-style-type: none;
    }

    &-item {
      padding: 4px;

      &__link {
        all: unset;

        &:hover {
          cursor: pointer;
          color: #FF760D;
        }

        &.-is-active {
          border-bottom: 1px solid #FF760D;
        }
      }
    }
  }
}