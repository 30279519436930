$spacer: 8px;
.home-page {
    margin-top: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;

    .icon {
        color: white;
        padding: $spacer * 1.5;
        background-color: black;
        border-radius: 50%;
        border: 0;
        display: inline-flex;
        justify-content: center;

        margin-right: $spacer*2;
    }

    .home-page__intro {
        padding: $spacer*6;
        text-align:left;
        font-size: 2rem;
        line-height: 1.5;

        &-job-title {
            color: #3ce3b4;
            // text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black,
            //    1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
            // text-shadow: 1px 1px black;
            // font-weight: bold;
            font-style: normal;
        }

        &-icons {
            margin-top: $spacer*4;
        }
    }
}

@media only screen and (max-width: 800px) {
    .home-page {
        grid-template-columns: 1fr;

        .home-page__intro {
            text-align: center;
        }
    }
}